import React from 'react';
import '../css/Home.css';
import Navbar from './Navbar';
import Footer from './Footer';
import Logo from '../img/Recurso 2@4x.png';
import placeholder from '../img/league/marcet.png';
import { useTranslation } from 'react-i18next';
import banner from '../img/Banner_Patrocinadores.png';

import bayerIcon from '../img/clubs/club.png';

const clubIcons = Array.from({ length: 19 }, () => bayerIcon);

const Home = () => {
  const { t } = useTranslation();
  return (
    <div className="home-container">
      <Navbar />
      {/* <section className="clubs-section">
        <div className="clubs-bar">
          {clubIcons.map((icon, index) => (
            <img 
              key={index} 
              src={icon}
              alt={`Club ${index + 1}`} 
              className="club-icon" 
            />
          ))}
        </div>
      </section> */}
      <main className="main-content">
        <section className="hero-section">
        
          <div className="content-wrapper">
          <img className='banner-home' src={banner} ></img>
            {/* <div className="video-container">
              <div className="star"></div>
              <iframe 
                width="600" 
                height="315" 
                src="https://www.youtube.com/embed/8fxag-gpoF8?si=d8BUFUhH0jTj_1f1" 
                title="YouTube video player" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen>
              </iframe>
            </div>
            <div className="hero-text">
            <h1>{t('introTitle')}</h1>
            <p className="special-font">{t('introDescription')}</p>
            </div> */}
          </div>
          <div class="logo-position">
            <img src={Logo} alt="Logo" class="logo-image"/>
          </div>
        </section>
      <div className='home-content'>
        <section className="leagues-section content-wrapper">
          <div className="section-header">
            <h2>HYPER LEAGUES</h2>
          </div>
          <div className="leagues-grid">
          <a href="/hypearleague/SUB19-Grupo-1">
            <div className="league-card">
              <div className="card-content">
                <h3>SUB19 GRUPO 1</h3>
              </div>
            </div>
            </a>
            <a href="/hypearleague/SUB19-Grupo-2">
            <div className="league-card">
              <div className="card-content">
                <h3>SUB19 GRUPO 2</h3>
              </div>
            </div>
            </a>
            <a href="/hypearleague/SUB16-Grupo-1">
            <div className="league-card">
              <div className="card-content">
                <h3>SUB16 GRUPO 1</h3>
              </div>
            </div>
            </a>
            <a href="/hypearleague/SUB16-Grupo-2">
            <div className="league-card">           
              <div className="card-content">
                <h3>SUB16 GRUPO 2</h3>
              </div>
            </div>
            </a>
           
          </div>
        </section>
      </div>

        <section className="leagues-section content-wrapper" style={{paddingTop: "3.5rem", paddingBottom: "3.5rem"}}>
          <div className='mb-5'>
            <h2>POWERED by FEDERACIÓN CATALANA DE FÚTBOL (FCF)</h2>
            <p>La Hyperleague es una competición adherida a la Federación Catalana de Fútbol que es la institución que representa a la Federación Española de Futbol en Barcelona y Cataluña en virtud de su estructura administrativa regional
            </p>
            <p>Es una competición anual de carácter permanente que se rige por las normativas y reglamentos de la Federación Catalana de Fútbol (FCF) especialmente en virtud de su artículo 5.27 de sus estatutos, artículo 100 del RG de la FCF debiendo respetar en todo momento las RJ de la FIFA vigentes y las disposiciones reglamentarias de la FCF que sean de obligatoria aplicación.</p>      
          </div>
       

        <h2 >¿CÓMO FUNCIONA EL BIG DATA EN LA HYPERLEAGUE?</h2>
        <p>
          <strong>
          Técnicas de gestión de Big Data para generar información eficiente y rankings significativos en la Hyperleague proporcionado por el departamento de I+D de Marcet

          </strong>

      </p>
        <p>Hacer clasificaciones y rankings utilizando el Factor de Impacto Relacional (FIR) permite estandarizar los datos y hacer comparaciones más eficientes entre diferentes jugadores, equipos o cualquier conjunto de datos.         </p>
        
        <ol className='list-home'>

          <li> <h3>Comparación eficiente entre diferentes métricas</h3>

<p>El FIR convierte diferentes métricas (goles, asistencias, velocidad, etc.) en valores estandarizados, lo que permite compararlas aunque tengan escalas muy distintas. Esto es útil cuando evaluamos un jugador en varias áreas que no se miden con las mismas unidades o rangos (podemos, por ejemplo,comparar la precisión de pases -medida en porcentaje- con los goles marcados -medidos en unidades-, ya que ambos estarán convertidos a FIR sin importar la diferencia en sus escalas)
</p></li>
          <li>
          <h3>Detecta rendimientos sobresalientes o deficientes</h3>
        <p>El FIR muestra qué tan lejos está un valor (por ejemplo, el rendimiento de un jugador) del promedio. Así puedes identificar fácilmente si alguien está muy por encima o muy por debajo del nivel promedio en una métrica específica.
        </p>

          </li>
          <li>

          <h3>Facilita la comparación entre grupos diferentes</h3>

<p>El Factor de Impacto Relacional (FIR) permite comparar jugadores que están en grupos diferentes, como entre equipos, ligas o incluso edades, ya que estandarizan los datos y eliminan las diferencias inherentes entre grupos.
</p>
          </li>
          <li>

          <h3>Identifica puntos fuertes y débiles</h3>

<p>Al calcular el FIR de varias métricas (como velocidad, goles, asistencias, etc.), puedes identificar en qué áreas un jugador está por encima del promedio y en cuáles necesita mejorar.
</p>
          </li>


<li>
<h3>Simplificación del análisis</h3>
        <p>El FIR simplifica la clasificación de datos complejos y los reduce a una única métrica estándar, lo que facilita la comparación y el análisis de grandes volúmenes de información.
        </p>

</li>

<li> 
<h3>Identifica rendimientos atípicos</h3>
<p>Los FIR también son útiles para detectar jugadores que están muy lejos del promedio (por mucho mejor o peor que los demás), conocidos como outliers. Esto es útil para identificar talentos excepcionales o áreas problemáticas. 
</p>
</li>


<li><h3>
Elimina el sesgo causado por la variación en la escala
  </h3>
  
  <p>El uso de FIR elimina el sesgo que podría surgir si una métrica tiene un rango mucho más amplio que otra. Sin FIR, las métricas con mayor variabilidad (como goles) podrían influir más en una clasificación que métricas con menor variabilidad (como asistencias).
  </p>
  </li>

  <li><h3>Creación de rankings más precisos</h3>
  <p>Hacer clasificaciones con FIR permite crear rankings más eficientes y precisos porque las puntuaciones se basan en el rendimiento relativo de cada jugador con respecto al grupo. Esto hace que sea más fácil diferenciar entre buenos y malos rendimientos.
  </p>
  </li>

  <li>
    <h3>Comparación entre jugadores de diferentes épocas o condiciones</h3>
    <p>El FIR nos permite comparar jugadores de diferentes épocas o diferentes contextos (equipos, ligas, condiciones de juego) porque se basa en el rendimiento relativo al grupo con el que se compara, eliminando las diferencias de época o entorno.
    </p>
    <p>Resumen de la optimización del Big Data mediante la aplicación de  Factor de Impacto Relacional (FIR)</p>

<ol className='list2-home'>

  <li><p>Comparación justa entre diferentes métricas y escalas.</p></li>
  <li> <p>Identificación de rendimientos sobresalientes o deficientes.</p></li>
  <li><p>Comparación entre diferentes grupos (equipos, ligas, edades).</p></li>
  <li><p>Detección de puntos fuertes y débiles en varias áreas.</p></li>
  <li><p>Simplificación del análisis de datos complejos.</p></li>
  <li><p>Identificación de outliers o rendimientos atípicos.</p></li>
  <li><p>Elimina el sesgo causado por métricas con escalas muy diferentes.</p></li>
  <li><p>Rankings más precisos basados en el rendimiento relativo.</p></li>
  <li><p>Comparación entre épocas o condiciones diferentes.</p></li>


</ol>
       
  </li>

 
        </ol>

      



       

       

   
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Home;
